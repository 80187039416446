<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Fehler bei der Abfrage der Daten</h4>
      <div class="alert-body">
        Es wurde kein Mitarbeiter mit dieser ID gefunden. Gehen Sie bitte zurück
        zur
        <b-link class="alert-link" :to="{ name: 'mitarbeiter' }">
          Mitarbeiterliste
        </b-link>
        und wählen Sie einen anderen Mitarbeiter aus.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" xl="9" lg="8" md="7">
          <mitarbeiter-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <mitarbeiter-auftrag-card />
        </b-col>
        <b-col cols="12">
          <employee-order-table :isAdmin="true" />
        </b-col>
        <b-col cols="12">
          <employee-order-table :finishedOrders="true" :isAdmin="true" />
        </b-col>
        <b-col cols="12">
          <mitarbeiterberichte-table />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue';
import MitarbeiterInfoCard from '@/layouts/components/widgets/MitarbeiterInfoCard.vue';
import MitarbeiterAuftragCard from '@/layouts/components/widgets/MitarbeiterAuftragCard.vue';
import EmployeeOrderTable from '@/layouts/components/tables/EmployeeOrderTable.vue';
import MitarbeiterberichteTable from '@/layouts/components/tables/MitarbeiterberichteTable.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    MitarbeiterInfoCard,
    MitarbeiterAuftragCard,
    EmployeeOrderTable,
    MitarbeiterberichteTable,
  },
  data() {
    return {
      userData: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Page Title
    to.meta.pageTitle = `Mitarbeiter`;
    next();
  },
  async created() {
    const qs = require('qs');
    const query = qs.stringify(
      {
        populate: [
          'Verfuegbarkeit',
          'Anschrift',
          'Kontakt',
          'Kenntnisse',
          'User.avatar',
        ],
      },
      {
        encodeValuesOnly: true,
        indices: false,
      }
    );
    await this.$http
      .get(`/mitarbeiters/${this.$route.params.id}?${query}`)
      .then(response => {
        this.userData = response.data.data;
      })
      .catch(err => console.log(err));

    // Change Page Title
    this.$route.meta.pageTitle = `Mitarbeiter ${this.userData.Vorname} ${this.userData.Nachname}`;
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });
  },
};
</script>

<style></style>
