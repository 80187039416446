var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"21","xl":"6"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":_vm.userData.User.avatar
              ? _vm.$dbBaseUrl + _vm.userData.User.avatar.formats.thumbnail.url
              : '',"text":("" + (_vm.userData.Vorname.charAt()) + (_vm.userData.Nachname.charAt())),"variant":"light-primary","size":"104px"}}),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.userData.Vorname)+" "+_vm._s(_vm.userData.Nachname)+" ")]),_c('a',{attrs:{"href":("mailto:" + (_vm.userData.User.email))}},[_c('span',{staticClass:"card-text"},[_vm._v(_vm._s(_vm.userData.User.email))])])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"to":{
                name: 'mitarbeiter-bearbeiten',
                params: { id: _vm.userData.id },
              },"variant":"primary"}},[_vm._v(" Bearbeiten ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal:[`modal-delete-employee-${userData.id}`]",arg:("modal-delete-employee-" + (_vm.userData.id))}],staticClass:"ml-1",attrs:{"variant":"outline-danger"}},[_vm._v(" Löschen ")]),_c('delete-employee-modal',{attrs:{"userData":_vm.userData}})],1)])],1),_c('div',{staticClass:"d-flex align-items-center mt-2"},[_c('div',{staticClass:"d-flex align-items-center mr-2"},[_c('b-avatar',{attrs:{"variant":"light-primary","rounded":""}},[_c('feather-icon',{attrs:{"icon":"ClockIcon","size":"18"}})],1),_c('div',{staticClass:"ml-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.monthlyHours)+" Stunden")]),_c('small',[_vm._v("Arbeitsstunden Monat")])])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"variant":"light-success","rounded":""}},[_c('feather-icon',{attrs:{"icon":"TrendingUpIcon","size":"18"}})],1),_c('div',{staticClass:"ml-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.totalHours)+" Stunden")]),_c('small',[_vm._v("Arbeitsstunden Jahr")])])],1)])]),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('table',{staticClass:"mt-2 mt-xl-0 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Username")])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.userData.User.username)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Status")])],1),_c('td',{staticClass:"pb-50 text-capitalize"},[_c('b-badge',{attrs:{"variant":_vm.status[1][
                  _vm.userData.Verfuegbar === 'Im_Auftrag'
                    ? 1
                    : _vm.userData.Verfuegbar === 'Verfuegbar'
                    ? 2
                    : _vm.userData.Verfuegbar === 'Krank'
                    ? 3
                    : 4
                ]}},[_vm._v(" "+_vm._s(_vm.status[0][ _vm.userData.Verfuegbar === 'Im_Auftrag' ? 1 : _vm.userData.Verfuegbar === 'Verfuegbar' ? 2 : _vm.userData.Verfuegbar === 'Krank' ? 3 : 4 ])+" ")])],1)]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Bewertung")])],1),_c('td',{staticClass:"pb-50 text-capitalize"},[_c('b-badge',{attrs:{"variant":_vm.rating[1][_vm.userData.Bewertung]}},[_vm._v(" "+_vm._s(_vm.userData.Bewertung)+" ")])],1)]),(_vm.userData.Kontakt.Telefon)?_c('tr',[_c('th',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"PhoneIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Telefon")])],1),_c('td',[_c('a',{attrs:{"href":("tel:" + (_vm.userData.Kontakt.Telefon))}},[_vm._v(_vm._s(_vm.userData.Kontakt.Telefon))])])]):_vm._e(),(_vm.userData.Kontakt.Mobil)?_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"SmartphoneIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Mobil")])],1),_c('td',{staticClass:"pb-50"},[_c('a',{attrs:{"href":("tel:" + (_vm.userData.Kontakt.Mobil))}},[_vm._v(_vm._s(_vm.userData.Kontakt.Mobil))])])]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }