<template>
  <b-card no-body class="border-primary">
    <b-card-header
      v-if="hasOrder"
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">Aktueller Auftrag</h5>
      <b-badge :variant="status[1][orderStatus(orderData.Status)]">
        {{ status[0][orderStatus(orderData.Status)] }}
      </b-badge>
      <small class="text-muted w-100">Juli 22, 2022</small>
    </b-card-header>

    <b-card-body v-if="hasOrder">
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">
            Auftragsnummer:
            <b-link
              :to="{
                name: 'auftrag-details',
                params: { id: orderData.id },
              }"
              >#{{ orderData.Auftragsnummer }}</b-link
            ></span
          >
        </li>
        <li class="my-25">
          <span class="align-middle">Kunde: {{ orderData.Kunde.Firma }}</span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="
          $router.push({
            name: 'auftrag-details',
            params: { id: orderData.id },
          })
        "
      >
        Zum Auftrag
      </b-button>
    </b-card-body>

    <b-card-body class="d-flex align-items-center" v-else>
      <h4 class="text-center">
        Mitarbeiter ist derzeit keinem Auftrag zugeordnet.
      </h4></b-card-body
    >
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BLink,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BLink,
  },
  data() {
    return {
      orderData: {
        Auftragsnummer: null,
        Status: null,
        Kunde: {
          Firma: null,
        },
      },
      status: [
        {
          1: 'Warte auf Freigabe',
          2: 'Bestätigt',
          3: 'Bereit',
          4: 'Laufend',
          5: 'Beendet',
          6: 'Unterbrochen',
          7: 'Abgebrochen',
        },
        {
          1: 'secondary',
          2: 'info',
          3: 'success',
          4: 'dark',
          5: 'primary',
          6: 'warning',
          7: 'danger',
        },
      ],
    };
  },
  async created() {
    await this.getOrder();
  },
  computed: {
    hasOrder() {
      return !!this.orderData?.Status;
    },
  },
  methods: {
    orderStatus(order) {
      switch (true) {
        case order === 'Schwebend':
          return 1;
        case order === 'Bestaetigt':
          return 2;
        case order === 'Bereit':
          return 3;
        case order === 'Laufend':
          return 4;
        case order === 'Beendet':
          return 5;
        case order === 'Ruht':
          return 6;
        case order === 'Abgebrochen':
          return 7;
        default:
          return 1;
      }
    },
    async getOrder() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id'],
          populate: {
            Auftraege: {
              fields: ['Status', 'Auftragsnummer'],
              filters: {
                $or: [
                  { Status: { $eq: 'Bestaetigt' } },
                  { Status: { $eq: 'Laufend' } },
                  { Status: { $eq: 'Bereit' } },
                  { Status: { $eq: 'Ruht' } },
                ],
              },
              populate: {
                Auftragsdetails: {
                  sort: ['Startdatum:asc'],
                },
                Kunde: {
                  fields: ['Firma'],
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/mitarbeiters/${this.$route.params.id}?${query}`)
        .then(response => {
          this.orderData = response.data.data.Auftraege[0];
        })
        .catch(err => console.log(err.response.data.error.message));
    },
  },
};
</script>
