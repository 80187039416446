<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="
              userData.User.avatar
                ? $dbBaseUrl + userData.User.avatar.formats.thumbnail.url
                : ''
            "
            :text="`${userData.Vorname.charAt()}${userData.Nachname.charAt()}`"
            :variant="`light-primary`"
            size="104px"
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.Vorname }} {{ userData.Nachname }}
              </h4>
              <a :href="`mailto:${userData.User.email}`"
                ><span class="card-text">{{ userData.User.email }}</span></a
              >
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'mitarbeiter-bearbeiten',
                  params: { id: userData.id },
                }"
                variant="primary"
              >
                Bearbeiten
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                v-b-modal:[`modal-delete-employee-${userData.id}`]
              >
                Löschen
              </b-button>
              <delete-employee-modal :userData="userData" />
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="ClockIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ monthlyHours }} Stunden</h5>
              <small>Arbeitsstunden Monat</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ totalHours }} Stunden</h5>
              <small>Arbeitsstunden Jahr</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.User.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                :variant="
                  status[1][
                    userData.Verfuegbar === 'Im_Auftrag'
                      ? 1
                      : userData.Verfuegbar === 'Verfuegbar'
                      ? 2
                      : userData.Verfuegbar === 'Krank'
                      ? 3
                      : 4
                  ]
                "
              >
                {{
                  status[0][
                    userData.Verfuegbar === 'Im_Auftrag'
                      ? 1
                      : userData.Verfuegbar === 'Verfuegbar'
                      ? 2
                      : userData.Verfuegbar === 'Krank'
                      ? 3
                      : 4
                  ]
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Bewertung</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="rating[1][userData.Bewertung]">
                {{ userData.Bewertung }}
              </b-badge>
            </td>
          </tr>
          <tr v-if="userData.Kontakt.Telefon">
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefon</span>
            </th>
            <td>
              <a :href="`tel:${userData.Kontakt.Telefon}`">{{
                userData.Kontakt.Telefon
              }}</a>
            </td>
          </tr>
          <tr v-if="userData.Kontakt.Mobil">
            <th class="pb-50">
              <feather-icon icon="SmartphoneIcon" class="mr-75" />
              <span class="font-weight-bold">Mobil</span>
            </th>
            <td class="pb-50">
              <a :href="`tel:${userData.Kontakt.Mobil}`">{{
                userData.Kontakt.Mobil
              }}</a>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  VBModal,
} from 'bootstrap-vue';
import DeleteEmployeeModal from '@/layouts/components/modals/DeleteEmployeeModal.vue';
// import useUsersList from '../users-list/useUsersList';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    VBModal,
    DeleteEmployeeModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      monthlyHours: 0,
      totalHours: 0,
      status: [
        {
          1: 'Im Auftrag',
          2: 'Verfügbar',
          3: 'Krank',
          4: '-',
        },
        {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'secondary',
        },
      ],
      rating: [
        {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
        },
        {
          1: 'success',
          2: 'primary',
          3: 'info',
          4: 'secondary',
          5: 'warning',
          6: 'danger',
        },
      ],
    };
  },
  async created() {
    await this.getWorkingHours('monthly');
    await this.getWorkingHours();
  },
  methods: {
    async getWorkingHours(timeframe) {
      let startDate = new Date(
        new Date().toISOString().substring(0, 5) + '01-01'
      );

      if (timeframe === 'monthly') {
        const firstOfMonth = new Date().toISOString().substring(0, 8) + '01';
        startDate = new Date(firstOfMonth);
      }

      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id'],
          populate: {
            Pruefpositionen: {
              fields: ['id'],
              populate: {
                Mitarbeiterleistungen: {
                  filters: {
                    Arbeitsbeginn: {
                      $gte: startDate,
                    },
                  },
                  fields: ['Arbeitszeit'],
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/mitarbeiters/${this.$route.params.id}?${query}`)
        .then(response => {
          const pruefpositionen = response.data.data.Pruefpositionen;
          let totalHours = 0;
          let totalMinutes = 0;

          pruefpositionen.forEach(pruefposition => {
            pruefposition.Mitarbeiterleistungen.forEach(mitarbeiterleistung => {
              const [hours, minutes] =
                mitarbeiterleistung.Arbeitszeit.split(':');
              totalHours += +hours;
              totalMinutes += +minutes;
            });
          });

          totalHours = Math.round(totalHours + totalMinutes / 60);

          if (timeframe === 'monthly') {
            this.monthlyHours = totalHours;
          } else {
            this.totalHours = totalHours;
          }
        })
        .catch(err => console.log(err.response.data.error.message));
    },
  },
};
</script>

<style></style>
