<template>
  <b-card title="Mitarbeiterberichte" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="ClipboardIcon" />
        <b-card-title class="ml-25">Mitarbeiterberichte</b-card-title>
      </div>
    </b-card-header>
    <div class="mx-2 mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mb-0">Zeige</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label class="mb-0">Berichte</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filters.searchQuery"
              class="d-inline-block mx-1"
              placeholder="Suchen..."
            />
            <b-button
              variant="primary"
              @click="
                $router.push({
                  name: 'mitarbeiterbericht-erstellen',
                  params: { id: $route.params.id },
                })
              "
            >
              <span class="text-nowrap">Mitarbeiterbericht hinzufügen</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        :key="tableKey"
        :items="mitarbeiterberichteItemsProvider"
        :fields="fields"
        :per-page="0"
        :current-page="currentPage"
        :filter="filters"
        sort-by="Mitarbeiterberichtnummer"
        striped
        responsive
        class="mb-0"
      >
        <!-- Column: Actions -->
        <template #cell(Aktionen)="row">
          <div class="text-nowrap">
            <!--
            <feather-icon
              :id="`mitarbeiterbericht-row-${row.item.id}-preview-icon`"
              icon="EyeIcon"
              size="20"
              class="mr-50 cursor-pointer text-primary"
              @click="row.toggleDetails"
            />
            <b-tooltip
              title="Infos einblenden"
              class="cursor-pointer"
              :target="`mitarbeiterbericht-row-${row.item.id}-preview-icon`"
            />
            -->
            <feather-icon
              :id="`mitarbeiterbericht-row-${row.item.id}-details-icon`"
              icon="FileTextIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'mitarbeiterbericht-vorschau',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Details"
              placement="left"
              class="cursor-pointer"
              :target="`mitarbeiterbericht-row-${row.item.id}-details-icon`"
            />
            <feather-icon
              :id="`mitarbeiterbericht-row-${row.item.id}-edit-icon`"
              icon="EditIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'mitarbeiterbericht-bearbeiten',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Bearbeiten"
              placement="left"
              class="cursor-pointer"
              :target="`mitarbeiterbericht-row-${row.item.id}-edit-icon`"
            />
            <!-- 
            <feather-icon
              :id="`mitarbeiterbericht-row-${row.item.id}-download-icon`"
              icon="DownloadIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
            />
            <b-tooltip
              title="Herunterladen"
              class="cursor-pointer"
              :target="`mitarbeiterbericht-row-${row.item.id}-download-icon`"
            />
            -->
          </div>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card class="details-card">
            <h4 class="mb-1">Prüfpositionen</h4>
            <b-row class="mx-0">
              <b-col md="12" class="mb-1">
                <b-card
                  class="mb-1 details-card"
                  v-for="(pruefposition, index) in row.item.Pruefpositionen"
                  :key="pruefposition.id"
                >
                  <h5 class="mb-1">
                    <b>Pruefposition {{ index + 1 }}</b>
                  </h5>
                  <b-row class="align-items-center mx-1">
                    <b-col cols="4" class="mb-1">
                      <strong>Beschreibung: </strong
                      >{{ pruefposition.Bezeichnung }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Bauteilgruppe: </strong
                      >{{ pruefposition.Bauteilgruppe }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Stückzahl: </strong>{{ pruefposition.Stueckzahl }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Status: </strong>
                      <b-badge
                        :variant="status[1][orderStatus(pruefposition.Status)]"
                      >
                        {{ status[0][orderStatus(pruefposition.Status)] }}
                      </b-badge>
                    </b-col>
                    <b-col
                      cols="4"
                      class="mb-1"
                      v-if="pruefposition.Folgelieferung_Pruefen"
                    >
                      <strong>Folgelieferung Prüfen: </strong>
                      <b-form-checkbox
                        class="d-inline-block"
                        :checked="pruefposition.Folgelieferung_Pruefen"
                        disabled
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Mitarbeiter</h4>
            <b-row class="mx-0">
              <b-col
                md="4"
                class="mb-1"
                v-for="mitarbeiter in row.item.Mitarbeiter"
                :key="mitarbeiter.id"
              >
                <b-card class="details-card">
                  <b-media vertical-align="center" class="align-items-center">
                    <template #aside>
                      <b-avatar
                        size="100"
                        :src="
                          mitarbeiter.User.avatar
                            ? $dbBaseUrl +
                              mitarbeiter.User.avatar.formats.thumbnail.url
                            : ''
                        "
                        :text="`${mitarbeiter.Vorname.charAt()}${mitarbeiter.Nachname.charAt()}`"
                        :variant="`light-primary`"
                        :to="{
                          name: 'mitarbeiter-ansicht',
                          params: { id: mitarbeiter.id },
                        }"
                      />
                    </template>
                    <b-link
                      :to="{
                        name: 'mitarbeiter-ansicht',
                        params: { id: mitarbeiter.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ `${mitarbeiter.Vorname} ${mitarbeiter.Nachname}` }}
                    </b-link>
                    <small class="text-muted">{{
                      mitarbeiter.User.email
                    }}</small>
                  </b-media>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Details ausblenden
            </b-button>
          </b-card>
        </template>

        <!-- Column: Mitarbeiterberichtnummer -->
        <template #cell(Mitarbeiterberichtnummer)="data">
          <b-link
            :to="{
              name: 'mitarbeiterbericht-vorschau',
              params: { id: data.item.id },
            }"
            >#{{ data.item.Mitarbeiterberichtnummer }}</b-link
          >
        </template>

        <!-- Column: Mitarbeiterberichtdatum -->
        <template #cell(Mitarbeiterbericht_Datum)="data">
          {{
            new Date(data.item.Mitarbeiterbericht_Datum).toLocaleDateString(
              'de-DE',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )
          }}
        </template>

        <!-- Column: Startdatum -->
        <template #cell(Startdatum)="data">
          {{
            new Date(data.item.Startdatum).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          }}
        </template>

        <!-- Column: Enddatum -->
        <template #cell(Enddatum)="data">
          {{
            new Date(data.item.Enddatum).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          }}
        </template>

        <!-- Column: Arbeitsstunden -->
        <template #cell(Arbeitsstunden)="data">
          {{
            convertToIndustrialHours(
              data.item.Mitarbeiterleistungen.gesamt.Arbeitszeit
            )
          }}
          ih
        </template>
      </b-table>
    </div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Zeigt Bericht {{ dataMeta.from }} bis {{ dataMeta.to }} von
            {{ dataMeta.of }} Berichten</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BTooltip,
  BMedia,
  BLink,
  VBTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormInput,
    BTooltip,
    BMedia,
    BLink,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10],
      totalRows: 0,
      tableKey: 1,
      orderID: '',
      filters: { searchQuery: '', statusFilter: '' },
      fields: [
        {
          key: 'Mitarbeiterberichtnummer',
          label: 'Berichtnummer',
          sortable: true,
        },
        {
          key: 'Mitarbeiterbericht_Datum',
          label: 'Erstellt am',
          sortable: true,
        },
        { key: 'Startdatum', label: 'Startdatum', sortable: true },
        { key: 'Enddatum', label: 'Enddatum', sortable: true },
        {
          key: 'Arbeitsstunden',
          label: 'Arbeitsstunden',
        },
        { key: 'Aktionen', label: 'Aktionen' },
      ],
    };
  },
  computed: {
    dataMeta() {
      const fromItems = this.perPage * (this.currentPage - 1) + 1;
      const toItems = this.perPage * (this.currentPage - 1) + this.perPage;

      return {
        from: fromItems,
        to:
          toItems < this.totalRows
            ? toItems
            : toItems - (toItems - this.totalRows),
        of: this.totalRows,
      };
    },
  },
  watch: {
    perPage(newValue, oldValue) {
      if (newValue !== oldValue) this.refetchTable();
    },
  },
  methods: {
    refetchTable() {
      this.tableKey += 1;
    },
    convertToIndustrialHours(timeString) {
      const timeParts = timeString.split(':');
      const hours = +timeParts[0];
      const minutes = Math.round((+timeParts[1] / 60) * 100);
      return `${hours},${minutes}`;
    },
    async mitarbeiterberichteItemsProvider(ctx) {
      switch (true) {
        case ctx.sortBy === '' || ctx.sortBy === 'Mitarbeiterberichtnummer':
          ctx.sortBy = 'Mitarbeiterberichtnummer';
          break;
        default:
          break;
      }

      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: ctx.currentPage,
            pageSize: this.perPage,
          },
          filters: {
            Mitarbeiter: {
              id: {
                $eq: this.$route.params.id,
              },
            },
          },
          fields: '*',
          sort: [`${ctx.sortBy}:${ctx.sortDesc ? 'desc' : 'asc'}`],
          populate: {
            Mitarbeiter: {
              fields: ['id'],
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let mitarbeiterberichtItems = [];

      await this.$http
        .get(`/mitarbeiterberichte?${query}`)
        .then(response => {
          mitarbeiterberichtItems = response.data.data;
          this.totalRows = mitarbeiterberichtItems.length;
        })
        .catch(err => console.log(err.response.data.error.message));
      return mitarbeiterberichtItems;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#status-selection {
  min-width: 15rem;
}
.per-page-selector {
  min-width: 6rem;
}

.google-map {
  height: 20rem;
  border-radius: 0.5rem;

  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
